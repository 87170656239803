import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import useEmblaCarousel from 'embla-carousel-react';
import SanityImage from 'gatsby-plugin-sanity-image';
import Video from './video';
import { Back, Close, Info, Right } from './icons';
import CaptionCard from './caption-card';

export default function Gallery({
  className,
  title,
  invertForeground,
  background,
  items,
  closeOnClick,
  currentSlide,
}) {
  const [emblaRef, embla] = useEmblaCarousel({ loop: true });
  const [current, setCurrent] = useState(currentSlide ? currentSlide : 0);
  const [showInfo, setShowInfo] = useState(false);
  const [descriptionExists, setDescriptionExists] = useState(
    items[current]?.captionTitle || items[current]?.captionText
  );

  let currentPadded = String(current + 1).padStart(2, '0');
  let totalPadded = items && String(items.length).padStart(2, '0');

  useEffect(() => {
    embla?.on('select', () => {
      setCurrent(embla.slidesInView(true)[0]);
      setDescriptionExists(
        items[embla.slidesInView(true)[0]].captionTitle ||
          items[embla.slidesInView(true)[0]].captionText
      );
    });
  }, [embla]);

  useEffect(() => {
    embla?.scrollTo(currentSlide, { jump: true });
    setDescriptionExists(items[current]?.captionTitle || items[current]?.captionText);
  }, [currentSlide]);

  return (
    <div
      className={className}
      css={css`
        display: flex;
        flex-direction: column;
        color: ${invertForeground ? 'var(--white)' : 'var(--black)'};
        background-color: ${background};
        width: 100%;
        height: 100%;
        padding: 1.3rem var(--gutterHalf) var(--gutterHalf);
      `}
    >
      <div
        css={css`
          position: relative;
          display: flex;
          padding-bottom: var(--gutterHalf);
        `}
      >
        <div
          className="type--heading-four"
          css={css`
            flex-grow: 1;
            margin-right: var(--gutter);
          `}
        >
          {title ? `${title} (Gallery)` : ''}
        </div>
        <Info
          invert={invertForeground}
          transparent={!invertForeground}
          hover={true}
          active={showInfo}
          onClick={() => setShowInfo(!showInfo)}
          css={css`
            opacity: ${descriptionExists ? '1' : '0.5'};
            pointer-events: ${descriptionExists ? 'inherit' : 'none'};
          `}
        />
        <Close
          invert={invertForeground}
          transparent={!invertForeground}
          hover={true}
          onClick={closeOnClick}
          css={css`
            margin-left: var(--gutterHalf);
          `}
        />
        <div
          css={css`
            position: absolute;
            display: ${showInfo ? 'block' : 'none'};
            top: calc(100% + var(--gutterHalf));
            right: var(--gutterHalf);
            filter: var(--dropShadow);
            z-index: 1;
          `}
        >
          {items &&
            items.map((item, i) => {
              if (item.captionTitle || item.captionText) {
                return (
                  <CaptionCard
                    key={i}
                    monoHeading={item.captionTitle}
                    text={item.captionText}
                    invert={true}
                    css={css`
                      position: absolute;
                      display: ${i === current ? 'block' : 'none'};
                      top: 0 !important;
                      right: 0 !important;
                    `}
                  />
                );
              } else return;
            })}
        </div>
      </div>
      <div
        className="embla"
        ref={emblaRef}
        css={css`
          width: 100%;
          flex-grow: 1;
          overflow: hidden;
        `}
      >
        <div
          className="embla__container"
          css={css`
            display: flex;
            height: 100%;
          `}
        >
          {items &&
            items.map((item, i) => {
              return (
                <div
                  key={i}
                  css={css`
                    position: relative;
                    display: flex;
                    flex: 0 0 100%;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                  `}
                >
                  {item.assetType === 'video' && (
                    <Video
                      src={item.src}
                      css={css`
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 100%;

                        video {
                          width: auto;
                          max-width: 100%;
                          height: auto;
                          max-height: 100%;
                          object-fit: contain;
                        }
                      `}
                    />
                  )}
                  {item.assetType === 'image' && item.image && (
                    <SanityImage
                      {...item.image}
                      width={1980}
                      loading="eager"
                      style={{
                        display: 'block',
                        maxWidth: '100%',
                        maxHeight: '100%',
                        objectFit: 'contain',
                      }}
                    />
                  )}
                </div>
              );
            })}
        </div>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: var(--gutterHalf);
        `}
      >
        {items && items.length > 1 && (
          <Back
            invert={invertForeground}
            transparent={!invertForeground}
            hover={true}
            onClick={() => embla?.scrollPrev()}
          />
        )}
        <div
          className="type--paragraph"
          css={css`
            flex-grow: 1;
            text-align: center;
            margin-bottom: -1.1rem;
          `}
        >
          <span className="type--underline">{currentPadded}</span> of {totalPadded}
        </div>
        {items && items.length > 1 && (
          <Right
            invert={invertForeground}
            transparent={!invertForeground}
            hover={true}
            onClick={() => embla?.scrollNext()}
          />
        )}
      </div>
    </div>
  );
}
