import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import SalonItems from './salon-items';
import Gallery from './gallery';
import { Maximize } from './icons';
import Pill from './pill';

export default function Salon({ className, title, invertForeground, background, items }) {
  const [showGallery, setShowGallery] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const black = invertForeground ? 'var(--white)' : 'var(--black)';

  return (
    <div
      className={className}
      css={css`
        color: ${invertForeground ? 'var(--white)' : 'var(--black)'};
        background-color: ${background};
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          padding-bottom: var(--margin5);
          border-bottom: ${title ? `4px solid ${black}` : 'none'};
          margin-bottom: var(--margin11);

          @media (max-width: 800px) {
            display: block;
            padding-bottom: 0;
            border-bottom: none;
            margin-bottom: var(--margin5);
          }
        `}
      >
        {title && (
          <h2
            className="type--heading-three"
            css={css`
              @media (max-width: 800px) {
                display: block;
                padding-bottom: 1.5rem;
                border-bottom: 3px solid ${black};
                margin-bottom: 2.8rem;

                @media (max-width: 550px) {
                  margin-bottom: 4.8rem;
                }
              }
            `}
          >
            {title}
          </h2>
        )}
        <div
          css={css`
            @media (max-width: 800px) {
              text-align: ${title ? 'center' : 'left'};
            }
          `}
        >
          <Pill
            size={3}
            invert={invertForeground ? true : false}
            transparent={true}
            hover={true}
            text="View as Slideshow"
            onClick={() => {
              setCurrentSlide(0);
              setShowGallery(true);
            }}
          />
        </div>
      </div>
      <SalonItems
        items={items}
        maximizeOnClick={(e, i) => {
          setCurrentSlide(i);
          setShowGallery(true);
        }}
      />
      <div
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          opacity: ${showGallery ? '1' : '0'};
          pointer-events: ${showGallery ? 'auto' : 'none'};
          z-index: 1001;
        `}
      >
        <Gallery
          title={title}
          invertForeground={invertForeground}
          background={background}
          items={items}
          closeOnClick={() => setShowGallery(false)}
          currentSlide={currentSlide}
        />
      </div>
    </div>
  );
}
