import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import Image from './image';
import Video from './video';
import { Maximize } from './icons';
import BlockContent from './block-content';

export default function SalonItems({ className, items, maximizeOnClick }) {
  return (
    <div
      className={className}
      css={css`
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        gap: 18rem 5vw;

        @media (max-width: 1024px) {
          row-gap: var(--margin15);
        }

        @media (max-width: 800px) {
          row-gap: var(--margin12);
        }

        @media (max-width: 600px) {
          grid-template-columns: repeat(8, 1fr);
          gap: var(--gutter) var(--gutterHalf);
        }
      `}
    >
      {items.length > 0 &&
        items.map((item, i) => {
          return (
            <div
              key={i}
              css={css`
                position: relative;
                grid-column: span
                  ${item.src
                    ? 6
                    : item.image && item.image.asset.metadata.dimensions.aspectRatio > 1
                      ? (item.hero) ? 6 : 5 : (item.hero) ? 5 : 4};
                margin-top: ${i % 3 === 2 ? 'calc(var(--gutter) * 3)' : 0};

                @media (max-width: 800px) {
                  margin-left: 0;
                }

                @media (max-width: 600px) {
                  grid-column: span 8;
                }
              `}
            >
              {item.assetType === 'video' && <Video src={item.src} autoPlay={true} muted={true} loop={true} />}
              {item.assetType === 'image' && <Image image={item.image} width={990} />}
              <Maximize
                css={css`
                  position: absolute;
                  top: 1rem;
                  right: 1rem;
                  filter: var(--dropShadow);
                `}
                onClick={(e) => maximizeOnClick(e, i)}
              />
              {(item.captionTitle || item.captionText) && (
                <div
                  css={css`
                    margin-top: 2rem;

                    @media (max-width: 550px) {
                      margin-top: 1.7rem;
                    }
                  `}
                >
                  <div
                    className="type--mono-heading"
                    css={css`
                      margin-bottom: 0.5rem;
                    `}
                  >
                    {item.captionTitle}
                  </div>
                  <BlockContent content={item.captionText} />
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
